import React, { Component } from "react";
import { Button, Tabs, Anchor } from "antd";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import "rc-tween-one/assets/index.less";
import TextyAnim from "rc-texty";
import "rc-texty/assets/index.css";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";
import { Parallax, OverPack, Event, scrollScreen } from "rc-scroll-anim";
import "rc-scroll-anim/assets/index.css";
import Player from "react-background-video-player";
import Lottie from "lottie-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "./home.css";
import "./home1440.css";
import "./home1024.css";
import vid from "./video.mp4";
import { ReactComponent as Muted } from "./icons/muted.svg";
import { ReactComponent as Notmuted } from "./icons/notmuted.svg";
import { ReactComponent as PlayIcon } from "./icons/play.svg";
import s1ellipse from "./imgs/ellipse.svg";
import s1right from "./lottiejsons/screen1.json";
import crane from "./lottiejsons/crane.json";
import arrow from "./lottiejsons/arrow.json";
import point1 from "./imgs/point1.svg";
import point2 from "./imgs/point2.svg";
import s21 from "./imgs/s21.svg";
import s22 from "./imgs/s22.svg";
import s23 from "./imgs/s23.svg";
import s24 from "./imgs/s24.svg";
import s25 from "./imgs/s25.svg";
import tab1 from "./imgs/tab1.png";
import tab2 from "./imgs/tab2.png";
import tab3 from "./imgs/tab3.png";
import tab4 from "./imgs/tab4.png";
import round from "./imgs/round.svg";
import track1 from "./imgs/track1.svg";
import track2 from "./imgs/track2.svg";
import track3 from "./imgs/track3.svg";
import as1img from "./imgs/as1img.png";
import as2img from "./imgs/as2img.png";
import as3img from "./imgs/as3img.png";
import as4img from "./imgs/as4img.png";
import tlv1 from "./imgs/12icons/1.svg";
import tlv2 from "./imgs/12icons/2.svg";
import tlv3 from "./imgs/12icons/3.svg";
import tlv4 from "./imgs/12icons/4.svg";
import tlv5 from "./imgs/12icons/5.svg";
import tlv6 from "./imgs/12icons/6.svg";
import tlv7 from "./imgs/12icons/7.svg";
import tlv8 from "./imgs/12icons/8.svg";
import tlv9 from "./imgs/12icons/9.svg";
import tlv10 from "./imgs/12icons/10.svg";
import tlv11 from "./imgs/12icons/11.svg";
import tlv12 from "./imgs/12icons/12.svg";

TweenOne.plugins.push(Children);
const { Link } = Anchor;

gsap.registerPlugin(MotionPathPlugin);

class Home extends Component {
  playRef = React.createRef();
  swiperRef = React.createRef();
  craneLottieRef = React.createRef();

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  //滚动前的页码
  preScrollPage = 0;

  state = {
    isMuted: true, //静音
    videoOver: false, //视频是否播放完毕
    videoOpacity: 1, //视频透明度
    videoDuration: 0, //视频播放时长
    tabActiveKey: 0, //不解释
    num1Animation: null, //这哥四个是最下面的数字滚动动画
    num2Animation: null,
    num3Animation: null,
    num4Animation: null,
    showAnchor: true, //显示锚点
    firstScnPaused: true, //首屏文字动画暂停状态
    showFlyShape1: false, //第一个飞行图形显隐
    showFlyShape2: false,
    showFlyShape3: false,
    showJumpShape1: false, //第一个跳跃图形显隐
    showJumpShape2: false,
    showJumpShape3: false,
    showJumpShape4: false, //插头顶的蓝色静态三角块
    showJumpShape4Ani: false //蓝色三角变成橘色三角动画
  };

  //首屏划出文字的花里胡哨动画
  getEnter = (e) => {
    switch (e.index) {
      case 0:
        return {
          rotate: 90,
          opacity: 0,
          y: -60
        };
      case 10:
      case 1:
        return {
          y: -60,
          x: -10,
          opacity: 0
        };
      case 9:
      case 2:
        return {
          y: -60,
          x: 20,
          opacity: 0
        };
      case 3:
        return {
          y: 60,
          opacity: 0
        };
      case 8:
      case 4:
        return {
          x: 30,
          opacity: 0
        };
      case 5:
        return {
          enter: [
            {
              scale: 2,
              opacity: 0,
              type: "set"
            },
            { scale: 1.2, opacity: 1, duration: 300 },
            { scale: 0.9, duration: 200 },
            { scale: 1.05, duration: 150 },
            { scale: 1, duration: 100 }
          ],
          leave: {
            opacity: 0,
            scale: 0
          }
        };
      case 11:
      case 6:
        return {
          scale: 0.8,
          x: 30,
          y: -10,
          opacity: 0
        };
      case 12:
      case 7:
        return {
          scale: 0.8,
          x: 30,
          y: 10,
          opacity: 0
        };
      default:
        return {
          opacity: 0
        };
    }
  };

  componentDidMount() {
    scrollScreen.unMount();
    window.scrollTo(0, 0);
  }

  clickConnect = () => {
    this.props.showDrawer();
  };

  clickJoin = () => {
    this.props.joinUs();
  };

  onTrackOverPackChange = ({ mode }) => {
    this.preScrollPage = 0;
    scrollScreen.unMount();
    if (mode === "enter") {
      this.craneLottieRef.current.play();
    } else {
      //恢复播放0到125帧,别问我为啥知道一共是125帧,上面算的
      this.craneLottieRef.current.playSegments([0, 125], true);
      //跳到第0帧停下
      this.craneLottieRef.current.goToAndStop(0, true);
    }
  };

  //滚动条事件相关字段
  screen1Scroll = true;
  screen2Scroll = true;
  screen3Scroll = true;

  render() {
    return (
      <>
        {/* 第五屏的飞行侠们 */}
        {this.state.showFlyShape1 && (
          <div id='flyShape1Id' className='flyShape1'></div>
        )}
        {this.state.showFlyShape2 && (
          <div id='flyShape2Id' className='flyShape2'></div>
        )}
        {this.state.showFlyShape3 && (
          <div id='flyShape3Id' className='flyShape3'></div>
        )}
        {/* 首屏 视频 */}
        <div className='videoArea'>
          <Player
            disableBackgroundCover={false}
            ref={this.playRef}
            style={{
              zIndex: 1,
              opacity: this.state.videoOpacity,
              display: this.state.videoOver ? "none" : "block"
            }}
            containerWidth={this.windowWidth}
            containerHeight={this.windowHeight}
            extraVideoElementProps={{
              width: this.windowWidth
            }}
            src={vid}
            muted={this.state.isMuted}
            volume={1}
            loop={false}
            autoPlay={true}
            // startTime={10}
            onEnd={() => {
              this.setState({
                videoOver: true,
                firstScnPaused: false
              });
            }}
            onTimeUpdate={(currentTime) => {
              if (currentTime > 11) {
                this.setState({
                  videoOpacity: 0,
                  firstScnPaused: false
                });
              }
            }}
          />
          <div className='playerAction'>
            <Muted
              className='icon'
              fill='#000630'
              style={{ display: this.state.isMuted ? "inline" : "none" }}
              onClick={() => {
                this.setState({
                  isMuted: false
                });
              }}
            />
            <Notmuted
              className='icon'
              fill='#000630'
              style={{ display: this.state.isMuted ? "none" : "inline" }}
              onClick={() => {
                this.setState({
                  isMuted: true
                });
              }}
            />
            <PlayIcon
              style={{ display: this.state.videoOver ? "inline" : "none" }}
              className='icon'
              fill='#000630'
              onClick={() => {
                this.setState(
                  {
                    videoOver: false,
                    videoOpacity: 1
                  },
                  () => {
                    this.playRef.current.play();
                  }
                );
              }}
            />
          </div>
          {(this.state.videoOver || this.state.videoOpacity === 0) && (
            <div className='screen1content'>
              <div className='screen1left'>
                <TextyAnim
                  delay={5800}
                  duration={300}
                  enter={this.getEnter}
                  className='screenTitle'
                >
                  {!this.state.firstScnPaused && "打造鞋服产业"}
                </TextyAnim>
                <div key='screenTitle2' className='screenTitle'>
                  <TextyAnim
                    delay={5800}
                    duration={300}
                    enter={this.getEnter}
                    className='blueText'
                    component='span'
                  >
                    {!this.state.firstScnPaused && "智能制造"}
                  </TextyAnim>
                  <TextyAnim
                    duration={300}
                    delay={5800}
                    enter={this.getEnter}
                    component='span'
                  >
                    {!this.state.firstScnPaused && "协同体系"}
                  </TextyAnim>
                </div>
                <TextyAnim
                  duration={300}
                  delay={6000}
                  enter={this.getEnter}
                  className='screenSubTitle'
                >
                  {!this.state.firstScnPaused &&
                    "数乘·传统制造业数字化转型专家"}
                </TextyAnim>
                <div className='s1leftButtons'>
                  <TweenOne
                    animation={[
                      {
                        scale: 0,
                        opacity: 0,
                        duration: 0
                      },
                      {
                        scale: 1,
                        opacity: 1,
                        delay: 7300,
                        duration: 600,
                        ease: "easeOutBack"
                      }
                    ]}
                    paused={this.state.firstScnPaused}
                  >
                    <Button
                      type='primary'
                      className='leftButtons btnLeft'
                      onClick={this.clickConnect}
                    >
                      联系我们
                    </Button>
                  </TweenOne>
                  <TweenOne
                    animation={[
                      {
                        scale: 0,
                        opacity: 0,
                        duration: 0
                      },
                      {
                        scale: 1,
                        opacity: 1,
                        delay: 8000,
                        duration: 600,
                        ease: "easeOutBack"
                      }
                    ]}
                    paused={this.state.firstScnPaused}
                  >
                    <Button
                      type='default'
                      style={{
                        borderWidth: "2px"
                      }}
                      className='leftButtons btnRight'
                      onClick={this.clickJoin}
                    >
                      加入我们
                    </Button>
                  </TweenOne>
                </div>
              </div>
              <TweenOne
                animation={[
                  {
                    scale: 0,
                    opacity: 0,
                    duration: 0
                  },
                  {
                    scale: 1.2,
                    opacity: 1,
                    duration: 1500
                  },
                  {
                    x: this.windowWidth / 6,
                    y: 60,
                    scale: 1,
                    delay: 3000,
                    duration: 1000
                  }
                ]}
                paused={this.state.firstScnPaused}
                className='screen1right'
              >
                <Lottie
                  key='screen1rightkey'
                  animationData={s1right}
                  className='screen1Lottie'
                />
              </TweenOne>
              <TweenOne
                animation={[
                  {
                    width: "120vw",
                    opacity: 1,
                    duration: 0
                  },
                  {
                    width: "73vw",
                    delay: 4500,
                    duration: 1200
                  }
                ]}
                paused={this.state.firstScnPaused}
                className='bgBall'
              >
                <img src={s1ellipse} alt='' />
              </TweenOne>
            </div>
          )}
        </div>
        {/* 第二屏 表情 */}
        <div className='emoteArea' id='emoteArea'>
          <Parallax
            location='emoteArea'
            animation={{ z: 0, opacity: 1, playScale: [0.5, 1] }}
            style={{ transform: "translateZ(10px)", opacity: 0 }}
          >
            <img style={{ top: "17vh", left: "35vw" }} src={point1} alt='' />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={{ y: 0, opacity: 1, playScale: [0.1, 1] }}
            style={{ transform: "translateY(200px)", opacity: 0 }}
          >
            <img
              style={{ top: "10vh", left: "43vw", width: "33vw" }}
              src={s21}
              alt=''
            />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={{ z: 0, opacity: 1, playScale: [0.5, 1] }}
            style={{ transform: "translateZ(10px)", opacity: 0 }}
          >
            <img style={{ top: "16vh", right: "17vw" }} src={point1} alt='' />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={{ y: 0, opacity: 1, playScale: [0.5, 1] }}
            style={{ transform: "translateY(200px)", opacity: 0 }}
          >
            <img
              style={{ top: "28vh", left: "45vw", width: "20vw" }}
              src={s22}
              alt=''
            />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={{ z: 0, opacity: 1, playScale: [0.7, 1] }}
            style={{ transform: "translateZ(10px)", opacity: 0 }}
          >
            <img style={{ top: "42vh", left: "20vw" }} src={point2} alt='' />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={[
              { y: 0, opacity: 1, playScale: [0.3, 1] },
              { y: 400, x: 150, opacity: 0, playScale: [0.33, 0.5] }
            ]}
            style={{ transform: "translateY(200px)", opacity: 0 }}
          >
            <img
              style={{ top: "36vh", left: "28vw", width: "40vw" }}
              src={s23}
              alt=''
            />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={{ z: 0, opacity: 1, playScale: [0.7, 1] }}
            style={{ transform: "translateZ(10px)", opacity: 0 }}
          >
            <img style={{ top: "40vh", right: "27vw" }} src={point2} alt='' />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={[
              { y: 0, opacity: 1, playScale: [0.6, 0.85] },
              { y: 300, x: 60, playScale: [0.3, 0.55], opacity: 0 }
            ]}
            style={{ transform: "translateY(200px)", opacity: 0 }}
          >
            <img
              style={{ top: "58vh", left: "38vw", width: "7vw" }}
              src={s24}
              alt=''
            />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={[
              { y: 0, opacity: 1, playScale: [0.7, 0.9] },
              { y: 300, x: -100, playScale: [0.3, 0.55], opacity: 0 }
            ]}
            style={{ transform: "translateY(200px)", opacity: 0 }}
          >
            <img
              style={{ top: "52vh", left: "49vw", width: "26vw" }}
              src={s25}
              alt=''
            />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={[
              { z: 0, opacity: 1, playScale: [0.75, 1] },
              { y: 300, x: -100, playScale: [0.3, 0.55], opacity: 0 }
            ]}
            style={{ transform: "translateZ(10px)", opacity: 0 }}
          >
            <img style={{ top: "67vh", right: "45vw" }} src={point1} alt='' />
          </Parallax>
          <Parallax
            location='emoteArea'
            animation={[
              { y: 0, opacity: 1, playScale: [0.95, 1] },
              { y: 600, playScale: [0.5, 1], opacity: 0 }
            ]}
            style={{ transform: "translateY(20px)", opacity: 0 }}
          >
            <div className='painText'>您是否也存在这样的痛点?</div>
            <Lottie className='arrowLottie' animationData={arrow} />
            {/* <img src={s26} alt='' /> */}
            {/* <div className='arrowContainer'>
              <div className='arrow'></div>
              <div className='arrow'></div>
              <div className='arrow'></div>
            </div> */}
          </Parallax>
        </div>
        {/* 第三屏 横向滚动 */}
        <div className='tabsArea' id='tabsAreaId'>
          <OverPack replay playScale={[0.5, 0.8]} location='tabsAreaId'>
            <QueueAnim
              key='tabsareatitle'
              forcedReplay
              animConfig={{ opacity: [1, 0], translateY: [0, 20] }}
              style={{ height: "22vh" }}
            >
              <div className='title' key='title1'>
                传统制造业面临的时代挑战
              </div>
            </QueueAnim>
            <QueueAnim
              key='tabsareatitle2'
              forcedReplay
              animConfig={{ opacity: [1, 0], translateY: [0, 80] }}
              onEnd={({ key, type }) => {
                if (type === "enter") {
                  this.swiperRef.current.swiper.slideToLoop(0);
                }
              }}
            >
              <div key='tabs1'>
                <Tabs
                  activeKey={this.state.tabActiveKey}
                  centered
                  tabBarStyle={{ margin: "0 64px" }}
                  tabBarGutter='16vw'
                  items={[
                    {
                      label: <b className='tabLabel'>需求极端变化</b>,
                      key: 0
                    },
                    {
                      label: <b className='tabLabel'>并发供给变量</b>,
                      key: 1
                    },
                    {
                      label: <b className='tabLabel'>数据结构孤岛</b>,
                      key: 2
                    },
                    {
                      label: <b className='tabLabel'>难见质变成效</b>,
                      key: 3
                    }
                  ]}
                  onTabClick={(key, e) => {
                    this.swiperRef.current.swiper.slideToLoop(key);
                  }}
                />
              </div>
            </QueueAnim>
            <QueueAnim
              key='swiperqakey'
              forcedReplay
              animConfig={[
                {
                  opacity: [1, 0],
                  x: [0, this.windowWidth]
                },
                {
                  opacity: [1, 0],
                  x: [0, this.windowWidth * -1]
                }
              ]}
              duration={[500, 800]}
            >
              <div key='swipercontainerkey'>
                <Swiper
                  ref={this.swiperRef}
                  className='swiperSlider'
                  loop
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 5000
                  }}
                  onSlideChange={(s) => {
                    this.setState({
                      tabActiveKey: s.realIndex
                    });
                  }}
                >
                  <SwiperSlide className='slider slider1'>
                    {this.state.tabActiveKey === 0 && (
                      <QueueAnim
                        key='slider1left'
                        forcedReplay
                        leaveReverse
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [0, -700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <img key='tab1' className='left' src={tab1} alt='' />
                      </QueueAnim>
                    )}
                    {this.state.tabActiveKey === 0 && (
                      <QueueAnim
                        key='slider1right'
                        leaveReverse
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [1, 700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <div key='rightTabPanel1' className='rightTabPanel'>
                          <div className='raTitle'>
                            传统大制造模式，难以敏捷应对消费侧需求的极端变化
                          </div>
                          <div className='raSubTitle'>
                            逐渐常态化的消费侧需求极端变化，给传统制造业大生产模式相对确定的计划带来不确定性，应对不确定性的成本转移到产能消耗、资源损失、库存成本、运营成本等等。对于变化，拒不改变可能会死，盲目顺从可能会生不如死。如何降低牛鞭效应冲击，调整运营模式，跑在变化前主动把控，是自信的经营者们值得思考和谨慎解答的命题。
                          </div>
                          <div className='roundTag'>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>常态变化</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>不确定性</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>牛鞭冲击</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>应对成本</span>
                            </div>
                          </div>
                        </div>
                      </QueueAnim>
                    )}
                  </SwiperSlide>
                  <SwiperSlide className='slider slider2'>
                    {this.state.tabActiveKey === 1 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [0, -700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <img key='tab2' className='left' src={tab4} alt='' />
                      </QueueAnim>
                    )}
                    {this.state.tabActiveKey === 1 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [1, 700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <div key='rightTabPanel2' className='rightTabPanel'>
                          <div className='raTitle'>
                            传统供应链体系，无法快速适应过程节点的多并发变量
                          </div>
                          <div className='raSubTitle'>
                            全球化供应链体系按照效率最优的原则将供给节点高度拆解的同时，极大增加了节点变量风险，尤其是疫情当下的不可控因素和难以取悦的消费需求的互相叠加。精准的决策需要的是实时全面的数据变量因子和富有智慧的业务决策模型，很显然，组织侧信息传递效率和质量是不够客观的，人脑数据处理量和经验决策结果往往是不尽人意。
                          </div>
                          <div className='roundTag'>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>变量因子</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>节点风险</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>数据客观</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>经验决策</span>
                            </div>
                          </div>
                        </div>
                      </QueueAnim>
                    )}
                  </SwiperSlide>
                  <SwiperSlide className='slider slider3'>
                    {this.state.tabActiveKey === 2 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [0, -700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <img key='tab3' className='left' src={tab3} alt='' />
                      </QueueAnim>
                    )}
                    {this.state.tabActiveKey === 2 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [1, 700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <div key='rightTabPanel3' className='rightTabPanel'>
                          <div className='raTitle'>
                            传统信息化方式，天然形成的数据结构性孤岛产生隔阂
                          </div>
                          <div className='raSubTitle'>
                            信息化时代的解决方案，在批量、高效解决局部业务领域流程和数据管理问题上做出了巨大贡献。但是同时，其产品市场定位和价值模式决定了其形态必定会给当今数字化的期待带来结构化的隔离障碍，这种阻塞在业务单元内和跨组织间普遍存在。若数据流转无法全域畅通无阻，那么业务数据化的努力和数据业务化的尝试都变得没有意义。
                          </div>
                          <div className='roundTag'>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>局部问题</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>结构隔离</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>组间阻塞</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>全域畅通</span>
                            </div>
                          </div>
                        </div>
                      </QueueAnim>
                    )}
                  </SwiperSlide>
                  <SwiperSlide className='slider slider4'>
                    {this.state.tabActiveKey === 3 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [0, -700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <img key='tab4' className='left' src={tab2} alt='' />
                      </QueueAnim>
                    )}
                    {this.state.tabActiveKey === 3 && (
                      <QueueAnim
                        forcedReplay
                        animConfig={{
                          opacity: [1, 0],
                          translateX: [1, 700],
                          translateY: [0, 700]
                        }}
                        duration={1000}
                        ease='easeInOutBack'
                      >
                        <div key='rightTabPanel4' className='rightTabPanel'>
                          <div className='raTitle'>
                            传统人才方法，关注单点提效改善，难见全局质变成效
                          </div>
                          <div className='raSubTitle'>
                            百年大分工理论下的精益思想赋予了传统管理者降本增效的法宝，基于精益实践搭建的定制化系统更是为效率添了一把火。到了“能做的都做了”的阶段，其带来的收益增长曲线趋于平缓。埋头于业务的专家们依靠满满实践的经验方法，却很难跳出来，从高维度审视全局效率提升的可能性，更难反向思考抽象出的合工和协作的边界和模型。
                          </div>
                          <div className='roundTag'>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>百年分工</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>精益实践</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>增长平缓</span>
                            </div>
                            <div className='item'>
                              <img src={round} alt='' />
                              <span>反向思考</span>
                            </div>
                          </div>
                        </div>
                      </QueueAnim>
                    )}
                  </SwiperSlide>
                </Swiper>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
        {/* 第四屏 机械手+向左滚出 */}
        <div className='slideFormRight'>
          <div className='topArea'>
            <div className='title'>赋能多场景，驱动高增长</div>
            <Lottie
              lottieRef={this.craneLottieRef}
              className='crane'
              animationData={crane}
              loop={false}
              autoplay={false}
              onComplete={(e) => {
                // const frame = this.craneLottieRef.current.getDuration(true);
                // console.log("frame: ", frame); //125帧
                this.craneLottieRef.current.playSegments([85, 125], false);
              }}
            />
          </div>
          <div className='trackArea'>
            <div className='blueTrack'></div>
            <OverPack
              playScale={[0.4, 0.7]}
              replay
              onChange={this.onTrackOverPackChange}
              className='trackContainer'
            >
              <QueueAnim
                key='queue1'
                forcedReplay
                leaveReverse
                animConfig={{
                  translateX: [0, this.windowWidth]
                }}
                duration={[2000, 1000]}
                delay={[0, 400]}
                ease='easeOutQuad'
              >
                <img key='track1' className='trackImg' src={track1} alt='' />
              </QueueAnim>
              <QueueAnim
                key='queue2'
                forcedReplay
                leaveReverse
                animConfig={{
                  translateX: [0, this.windowWidth]
                }}
                duration={[2000, 1000]}
                delay={[500, 200]}
                ease='easeOutQuad'
              >
                <img key='track2' className='trackImg' src={track2} alt='' />
              </QueueAnim>
              <QueueAnim
                key='queue3'
                forcedReplay
                leaveReverse
                animConfig={{
                  translateX: [0, this.windowWidth]
                }}
                duration={[2000, 1000]}
                delay={[600, 0]}
                ease='easeOutQuad'
              >
                <img key='track3' className='trackImg' src={track3} alt='' />
              </QueueAnim>
            </OverPack>
          </div>
        </div>
        {/* 第五屏 锚点滚动 */}
        <div key='anChorScreen' className='anChorScreen'>
          {this.state.showAnchor && (
            <Anchor key='anchorTab' className='anchorTab' offsetTop={0}>
              <Link key='link1' href='#anChorScreen1' title='敬畏: 深入产业' />
              <Link key='link2' href='#anChorScreen2' title='尊重: 融入业务' />
              <Link key='link3' href='#anChorScreen3' title='思考: 抽象建模' />
              <Link key='link4' href='#anChorScreen4' title='务实: 落地为王' />
            </Anchor>
          )}
          <OverPack
            playScale={[0.8, 0.9]}
            className='anChorScreen1'
            id='anChorScreen1'
            replay
            onChange={(e) => {
              // console.log("进入屏1", this.preScrollPage, e.mode);
              if (e.mode === "enter") {
                if (this.preScrollPage >= 2) return;
                scrollScreen.init({
                  ease: "easeInOutSine",
                  scrollInterval: 800,
                  duration: 1000
                });
                const currentScrollTop = document.documentElement.scrollTop;
                Event.addEventListener("scroll.screen1", (e) => {
                  let scrollingTop = e.srcElement.documentElement.scrollTop;
                  if (
                    scrollingTop > currentScrollTop + 200 &&
                    this.screen1Scroll
                  ) {
                    this.screen1Scroll = false;
                    this.setState(
                      {
                        showFlyShape1: true,
                        showJumpShape1: false
                      },
                      () => {
                        gsap.to("#flyShape1Id", {
                          motionPath: {
                            path: [
                              {
                                x: this.windowWidth / -38.4,
                                y: this.windowWidth / -38.4
                              },
                              {
                                x: this.windowWidth / -6.4,
                                y: this.windowWidth / -5.5
                              },
                              {
                                x: this.windowWidth / -3,
                                y: this.windowWidth / -3.84
                              },
                              {
                                x: this.windowWidth / -1.7,
                                y: this.windowWidth / -7.68
                              },
                              {
                                x: this.windowWidth / -1.5,
                                y: this.windowWidth / -12.8
                              }
                            ],
                            autoRotate: true
                          },
                          duration: 1.35,
                          ease: "none",
                          onComplete: () => {
                            this.setState({
                              showFlyShape1: false
                            });
                          }
                        });
                      }
                    );
                  }
                });
                this.setState({
                  showAnchor: true
                });
                this.preScrollPage = 1;
              } else {
                this.setState({
                  showJumpShape1: false
                });
              }
            }}
          >
            <QueueAnim
              key='anChorScreen1'
              forcedReplay
              leaveReverse={false}
              animConfig={{
                translateY: [0, 300],
                opacity: [1, 0]
              }}
              duration={800}
            >
              <div key='asTitle' className='asTitle'>
                产业数字化转型，一场复杂度管理，一套建模体系
              </div>
            </QueueAnim>
            <div key='anChorScreen2' className='as1content'>
              <QueueAnim
                key='anChorScreen2left'
                forcedReplay
                leaveReverse={false}
                animConfig={[
                  {
                    opacity: [1, 0],
                    translateX: [0, this.windowWidth * -0.2],
                    translateY: [0, this.windowWidth * 0.2]
                  },
                  {
                    opacity: [1, 0],
                    translateX: [0, this.windowWidth * 0.2],
                    translateY: [0, this.windowWidth * -0.2]
                  }
                ]}
                duration={1000}
                ease='easeOutBack'
              >
                <div key='anleft1' className='rightTabPanel scrollTextArea'>
                  <div className='raTitle'>
                    深入产业 ，定义“客观价值”创造本质
                  </div>
                  <div className='raSubTitle'>
                    面对产业升级的焦虑，“数字化”被赋予了更高的期待。新瓶老酒的传统IT产品思路在全局提效和赋能转型上收效甚微，甚至带来制约，也逐渐让敏锐的经营者们失去信心而可能错过时机。我们认为，数字化作为新技术集合本身的价值属性不应该被神化，转型的起点也不应该是耳熟能详的的泛业务痛点，而是先从支撑业务的产业视角切入，充分理解这个产业客观存在的价值创造过程，并准确定位到其未来更高价值空间的锚点，才有机会定义出基于数字化理念和产业特性的解题范式。
                  </div>
                  <div className='roundTag'>
                    <div className='item'>
                      <img src={round} alt='' />
                      <span>产业焦虑</span>
                    </div>
                    <div className='item'>
                      <img src={round} alt='' />
                      <span>赋能转型</span>
                    </div>
                    <div className='item'>
                      <img src={round} alt='' />
                      <span>价值本质</span>
                    </div>
                    <div className='item'>
                      <img src={round} alt='' />
                      <span>解题范式</span>
                    </div>
                  </div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='anChorScreen2right'
                forcedReplay
                leaveReverse
                animConfig={[
                  {
                    opacity: [1, 0],
                    translateX: [1, this.windowWidth * 0.1],
                    translateY: [0, this.windowWidth * 0.2]
                  },
                  {
                    opacity: [1, 0],
                    translateX: [1, this.windowWidth * 0.1],
                    translateY: [1, this.windowWidth * 0.2]
                  }
                ]}
                duration={1000}
                ease='easeOutBack'
                onEnd={(e) => {
                  if (e.type === "enter") {
                    this.setState({
                      showJumpShape1: true
                    });
                    this.screen1Scroll = true;
                  }
                }}
              >
                <img key='as1img' src={as1img} className='as1img' alt='' />
              </QueueAnim>
              {this.state.showJumpShape1 && (
                <TweenOne
                  animation={[
                    {
                      y: 0,
                      rotate: 0,
                      duration: 0
                    },
                    {
                      y: this.windowWidth * -0.08,
                      rotate: 90,
                      duration: 800
                    },
                    {
                      y: 0,
                      rotate: 180,
                      duration: 800
                    }
                  ]}
                  repeat={-1}
                  paused={false}
                  className='jumpShape1'
                />
              )}
            </div>
          </OverPack>
          <OverPack
            replay
            key='op2'
            playScale={[0.5, 0.8]}
            className='anChorScreen2'
            id='anChorScreen2'
            onChange={(e) => {
              // console.log("进入屏2", this.preScrollPage, e.mode);
              if (e.mode === "enter") {
                this.preScrollPage = 2;
                const currentScrollTop = document.documentElement.scrollTop;
                Event.addEventListener("scroll.screen2", (e) => {
                  let scrollingTop = e.srcElement.documentElement.scrollTop;
                  if (
                    scrollingTop > currentScrollTop + 500 &&
                    this.screen2Scroll
                  ) {
                    this.screen2Scroll = false;
                    this.setState(
                      {
                        showFlyShape2: true,
                        showJumpShape2: false
                      },
                      () => {
                        gsap.to("#flyShape2Id", {
                          motionPath: {
                            path: [
                              {
                                x: this.windowWidth / 64,
                                y: this.windowWidth / -38.4
                              },
                              {
                                x: this.windowWidth / 19.2,
                                y: this.windowWidth / -12.8
                              },
                              {
                                x: this.windowWidth / 5.5,
                                y: this.windowWidth / -7.68
                              },
                              {
                                x: this.windowWidth / 1.92,
                                y: this.windowWidth / -64
                              },
                              {
                                x: this.windowWidth / 1.5,
                                y: this.windowWidth / 12.8
                              }
                            ],
                            autoRotate: true
                          },
                          duration: 1.38,
                          ease: "none",
                          onComplete: () => {
                            this.setState({
                              showFlyShape2: false
                            });
                          }
                        });
                      }
                    );
                  }
                });
              } else {
                this.setState({
                  showJumpShape2: false
                });
              }
            }}
          >
            <QueueAnim
              forcedReplay
              key='as2imgAnim1'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [0, this.windowWidth * -0.2],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
              onEnd={(e) => {
                if (e.type === "enter") {
                  this.setState({
                    showJumpShape2: true
                  });
                  this.screen2Scroll = true;
                }
              }}
            >
              <img key='as2img' src={as2img} className='as2img' alt='' />
            </QueueAnim>
            <QueueAnim
              forcedReplay
              key='as2imgAnim2'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [1, this.windowWidth * 0.1],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
            >
              <div key='anright2' className='rightTabPanel scrollTextArea'>
                <div className='raTitle'>融入业务，解构“最佳实践”底层逻辑</div>
                <div className='raSubTitle'>
                  业务调研中常会出现极左（用户教育）或极右（教育用户）的认知性偏差，而真正尊重业务并融入成为“他们”，才可能领会到用户那些“我们眼中不合理，他们眼中你不懂”的最佳实践。同时，保持基于全局价值目标和行业实践的独立思考和深度对话，才有机会解构出当前最佳实践的现实场景要素和管理科学底层逻辑，进而共创出兼具实用性和先进性的解决方案。在方案过程中，我们认同并善用现实作业中的“混效”模式（看似混乱却高效的线下协作），并定义好其应用规则和边界。
                </div>
                <div className='roundTag'>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>认知偏差</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>最佳实践</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>价值目标</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>混效模式</span>
                  </div>
                </div>
              </div>
            </QueueAnim>
            {this.state.showJumpShape2 && (
              <TweenOne
                animation={[
                  {
                    y: this.windowWidth * -0.08,
                    rotate: 90,
                    duration: 800
                  },
                  {
                    y: 0,
                    rotate: 180,
                    duration: 800
                  },
                  {
                    y: this.windowWidth * -0.08,
                    rotate: 270,
                    duration: 800
                  },
                  {
                    y: 0,
                    rotate: 360,
                    duration: 800
                  }
                ]}
                repeat={-1}
                paused={false}
                className='jumpShape2'
              />
            )}
          </OverPack>
          <OverPack
            replay
            key='op3'
            playScale={[0.5, 0.8]}
            id='anChorScreen3'
            className='anChorScreen2 anChorScreen3'
            onChange={(e) => {
              // console.log("进入屏3", this.preScrollPage, e.mode);
              if (e.mode === "enter") {
                this.preScrollPage = 3;
                const currentScrollTop = document.documentElement.scrollTop;
                Event.addEventListener("scroll.screen3", (e) => {
                  let scrollingTop = e.srcElement.documentElement.scrollTop;
                  if (
                    scrollingTop > currentScrollTop + 500 &&
                    this.screen3Scroll
                  ) {
                    this.screen3Scroll = false;
                    this.setState(
                      {
                        showFlyShape3: true,
                        showJumpShape3: false
                      },
                      () => {
                        gsap.to("#flyShape3Id", {
                          motionPath: {
                            path: [
                              {
                                x: this.windowWidth / -64,
                                y: this.windowWidth / -38.4
                              },
                              {
                                x: this.windowWidth / -7.68,
                                y: this.windowWidth / -5.5
                              },
                              {
                                x: this.windowWidth / -4,
                                y: this.windowWidth / -3.7
                              },
                              {
                                x: this.windowWidth / -2.4,
                                y: this.windowWidth / -4.36
                              },
                              {
                                x: this.windowWidth / -1.92,
                                y: this.windowWidth / -5.8
                              }
                            ]
                          },
                          duration: 1.4,
                          ease: "none",
                          onComplete: () => {
                            this.setState({
                              showFlyShape3: false
                            });
                          }
                        });
                      }
                    );
                  }
                });
              } else {
                this.setState({
                  showJumpShape3: false
                });
              }
            }}
          >
            <QueueAnim
              forcedReplay
              key='as2imgAnim1'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [0, this.windowWidth * -0.2],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
            >
              <div key='anleft3' className='rightTabPanel scrollTextArea'>
                <div className='raTitle'>抽象建模，打造“场景”产品能力框架</div>
                <div className='raSubTitle'>
                  不论传统定制化线性解题思路，还是当前备受追捧的SaaS轻量化模式，都被认为是无法高效处理企业级乃至产业级复杂需求并完成高质量全局性目标的落地。当前被我们实践证实有效且可靠的方法是基于业务领域边界划分、供需关系定义和场景化收敛的抽象建模。而我们通过实践，沉淀出一套结构化需求分析模型和框架化产品能力组合，进而让组织高效又不脱离架构目标地从容应对各种个性化需求难题。如果说个体面结构化思考是一种能力，那么组织面抽象化思维更是一种智慧。
                </div>
                <div className='roundTag'>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>领域边界</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>场景建模</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>框架能力</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>组织智慧</span>
                  </div>
                </div>
              </div>
            </QueueAnim>
            <QueueAnim
              forcedReplay
              key='as2imgAnim2'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [1, this.windowWidth * 0.1],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
              onEnd={(e) => {
                if (e.type === "enter") {
                  this.setState(
                    {
                      showJumpShape3: true
                    },
                    () => {
                      //碗里的球
                      gsap
                        .timeline({
                          yoyo: true,
                          repeat: -1
                        })
                        .to("#jumpShape3Id", {
                          y: this.windowWidth * -0.03,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          y: 0,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          y: this.windowWidth * -0.03,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          motionPath: {
                            path: [
                              {
                                x: this.windowWidth / 64,
                                y: this.windowWidth / -64
                              },
                              {
                                x: this.windowWidth / 36.2,
                                y: this.windowWidth / 64
                              },
                              {
                                x: this.windowWidth / 21,
                                y: this.windowWidth / 36.2
                              },
                              {
                                x: this.windowWidth / 20,
                                y: this.windowWidth / 34
                              },
                              {
                                x: this.windowWidth / 19.2,
                                y: this.windowWidth / 32
                              },
                              {
                                x: this.windowWidth / 18.64,
                                y: this.windowWidth / 45.7
                              },
                              {
                                x: this.windowWidth / 18.1,
                                y: this.windowWidth / 96
                              },
                              {
                                x: this.windowWidth / 12.5,
                                y: 0
                              }
                            ],
                            curviness: 1,
                            type: "soft"
                          },
                          duration: 1.5,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          y: this.windowWidth * -0.03,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          y: 0,
                          ease: "power1.inOut"
                        })
                        .to("#jumpShape3Id", {
                          y: this.windowWidth * -0.03,
                          ease: "power1.inOut"
                        });
                    }
                  );
                  this.screen3Scroll = true;
                }
              }}
            >
              <img key='as3img' src={as3img} className='as2img' alt='' />
            </QueueAnim>
            {this.state.showJumpShape3 && (
              <div className='jumpShape3' id='jumpShape3Id'></div>
            )}
          </OverPack>
          <OverPack
            id='anChorScreen4'
            key='op4'
            replay
            playScale={[0.4, 0.8]}
            onChange={(e) => {
              // console.log("进入屏4", this.preScrollPage, e.mode);
              if (e.mode === "enter") {
                this.setState({
                  showJumpShape4: true
                });
                if (this.preScrollPage <= 3) return;
                scrollScreen.init({
                  ease: "easeInOutSine",
                  scrollInterval: 800,
                  duration: 1000,
                  location: ["anChorScreen4"]
                });
                this.setState({
                  showAnchor: true
                });
                this.preScrollPage = 4;
              } else {
                this.setState({
                  showJumpShape4: false,
                  showJumpShape4Ani: false
                });
              }
            }}
            className='anChorScreen2'
          >
            <QueueAnim
              forcedReplay
              key='as2imgAnim1'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [0, this.windowWidth * -0.2],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
              onEnd={(e) => {
                if (e.type === "enter") {
                  this.setState({
                    showJumpShape4: false,
                    showJumpShape4Ani: true
                  });
                }
              }}
            >
              <img key='as4img' src={as4img} className='as2img' alt='' />
            </QueueAnim>
            <QueueAnim
              forcedReplay
              key='as2imgAnim2'
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateX: [1, this.windowWidth * 0.1],
                translateY: [0, this.windowWidth * 0.2]
              }}
              duration={1000}
              ease='easeOutBack'
            >
              <div key='anright4' className='rightTabPanel scrollTextArea'>
                <div className='raTitle'>落地为王，对“多维复杂度”管理过程</div>
                <div className='raSubTitle'>
                  企业级数字化转型，不论对管理者、用户还是系统建设者是一段充满嘈杂、彷徨，但又充满期待的漫长成长历程。而从其内在属性来看，这是一个对“多维复杂度”的管理过程，包括需求转化、技术架构、管理科学、新老系统交替等。基于对前述产业发展、业务价值、核心目标的理解，以及自身构建的产品应用框架和过程交付体系，我们可以清晰定义好阶段边界和预期产出，动态平衡好突发个体需求和全局表现在整个转型生命周期中的位置，进而从容应对不确定性，稳定输出落地节奏。
                </div>
                <div className='roundTag'>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>漫长历程</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>复杂度管理</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>稳定输出</span>
                  </div>
                  <div className='item'>
                    <img src={round} alt='' />
                    <span>可见预期</span>
                  </div>
                </div>
              </div>
            </QueueAnim>
            {this.state.showJumpShape4Ani && (
              <TweenOne
                animation={[
                  {
                    rotate: 30,
                    duration: 800
                  },
                  {
                    rotate: 60,
                    duration: 800
                  }
                ]}
                repeat={-1}
                paused={false}
                className='jumpShape4'
              />
            )}
            {this.state.showJumpShape4 && <div className='jumpShape04'></div>}
          </OverPack>
        </div>
        {/* 第六屏 一个大脑六大能力 */}
        <div className='sixAbilitiesScreen' id='sixAbilitiesScreenId'>
          <OverPack
            location='sixAbilitiesScreenId'
            playScale={[0.5, 0.7]}
            replay
            onChange={(e) => {
              if (e.mode === "enter") {
                this.preScrollPage = 5;
                scrollScreen.unMount();
                this.setState({
                  showAnchor: false
                });
              }
            }}
          >
            <QueueAnim
              key='sixKey1'
              forcedReplay
              animConfig={{ translateY: [0, 300], opacity: [1, 0] }}
              duration={600}
              interval={300}
              leaveReverse
              className='title'
            >
              <div key='titltT1' className='title1'>
                一个大脑，六大能力
              </div>
              <div key='titleT2' className='title2'>
                加速鞋服研发制造全场景打通、全链路升级
              </div>
            </QueueAnim>
            <QueueAnim
              key='sixKey2'
              forcedReplay
              animConfig={{ translateY: [0, 200], opacity: [1, 0] }}
              duration={600}
              delay={200}
              leaveReverse
            >
              <div key='textarroundContent' className='content'>
                <Lottie
                  key='textarroundLottie'
                  className='sixAbilitiesLottie'
                  animationData={s1right}
                />
                <QueueAnim
                  forcedReplay
                  type='scale'
                  interval={0}
                  ease='easeInQuad'
                  delay={2000}
                >
                  <div key='textArnd1' className='textBlock leftTop'>
                    “目标即项目，事事皆任务”
                    ，项目是目标分解管理及执行过程数据集合的中心场所，任务是驱动项目内过程数据流转的生产事件，两者聚合形成制造业的新协作关系。
                  </div>
                  <div key='textArnd2' className='textBlock leftCenter'>
                    不同类型BOM承载着用料、工艺、耗用等各用途的大量源信息，通过跨场景整合兼具扁平交互体验的MagiBOM架构，让设计研发大幅提效，同时极速赋能下游生产。
                  </div>
                  <div key='textArnd3' className='textBlock leftBottom'>
                    复杂离散制造业面对订单需求波动及配套资源供给的不确定性变得异常被动，通过基于供需要素深度拆解，动态捕捉，自适应决策算法，消除传统APS排程的无力感。
                  </div>
                  <div key='textArnd4' className='textBlock rightTop'>
                    分工协作是生产单元持续被输入、不断再输出的过程，复杂场景下非线性节点交互对跨单元数据资源获取要求极高，与项目任务打通的在线结构化数据空间成为中枢。
                  </div>
                  <div key='textArnd5' className='textBlock rightCenter'>
                    以往通过“冗余计划”和“下游库存转移”等降低生产配套风险的方式，对可持续供应链产生巨大压力，通过供需全域平衡算法驱动跨组织链接，可塑造柔性供应链能力。
                  </div>
                  <div key='textArnd6' className='textBlock rightBottom'>
                    有别一体化大屏看板的展示属性，业务孪生具象收敛各层级管理所需有效聚合结果信息，通过抽象业务决策模型，让场景化助手提供可选方案而不止于信息呈现。
                  </div>
                </QueueAnim>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
        {/* 第七屏 十二大产品 */}
        <div className='sixAbilitiesScreen twelveScreen'>
          <OverPack playScale={[0.5, 0.7]} replay>
            <QueueAnim
              key='twvTitle0'
              forcedReplay
              animConfig={{ opacity: [1, 0], translateY: [0, 300] }}
              duration={600}
              interval={300}
              leaveReverse={false}
              className='title'
            >
              <div key='twvTitle1' className='title1'>
                十二大产品功能领域
              </div>
              <div key='twvTitle2' className='title2'>
                再定义智能制造核心业务场景
              </div>
            </QueueAnim>
            <QueueAnim
              key='tlvAreaKey'
              className='tlvArea'
              animConfig={{ scale: [1, 0] }}
              forcedReplay
              leaveReverse
              ease='easeOutBack'
            >
              <QueueAnim
                key='qa1'
                delay={100}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img1' src={tlv1} alt='' />
                <div key='title1' className='itemTitles'>
                  <div className='mainTitle'>研发项目管理</div>
                  <div>打通研发管理全流程与全场景</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa2'
                delay={200}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img2' src={tlv2} alt='' />
                <div key='title2' className='itemTitles'>
                  <div className='mainTitle'>在线文档管理</div>
                  <div>支持文档在线协同及版本管理</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa3'
                delay={300}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img3' src={tlv3} alt='' />
                <div key='title3' className='itemTitles'>
                  <div className='mainTitle'>BOM 配方管理</div>
                  <div>实现 BOM 结构化、用途类管理</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa4'
                delay={400}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img4' src={tlv4} alt='' />
                <div key='title4' className='itemTitles'>
                  <div className='mainTitle'>工艺路线管理</div>
                  <div>灵活配置执行工序组合流转</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa5'
                delay={500}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img5' src={tlv5} alt='' />
                <div key='title5' className='itemTitles'>
                  <div className='mainTitle'>订单协同管理</div>
                  <div>统一订单接单服务管理平台</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa6'
                delay={600}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img6' src={tlv6} alt='' />
                <div key='title6' className='itemTitles'>
                  <div className='mainTitle'>生产物料管理</div>
                  <div>海量多维物料生命周期管理</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa7'
                delay={700}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img7' src={tlv7} alt='' />
                <div key='title7' className='itemTitles'>
                  <div className='mainTitle'>模具制具管理</div>
                  <div>模制具资产状态使用过程在线</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa8'
                delay={800}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img8' src={tlv8} alt='' />
                <div key='title8' className='itemTitles'>
                  <div className='mainTitle'>制造设备管理</div>
                  <div>OT设备实现双向实时打通</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa9'
                delay={900}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img9' src={tlv9} alt='' />
                <div key='title9' className='itemTitles'>
                  <div className='mainTitle'>采购协同管理</div>
                  <div>采购决策及自动化执行机制</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa10'
                delay={1000}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img10' src={tlv10} alt='' />
                <div key='title10' className='itemTitles'>
                  <div className='mainTitle'>生产执行管理</div>
                  <div>生产执行动态跟踪调节管理</div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa11'
                delay={1100}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img11' src={tlv11} alt='' />
                <div key='title11' className='itemTitles'>
                  <div className='mainTitle'>质量效能管理</div>
                  <div>质量可视 过程规范 可溯定位 </div>
                </div>
              </QueueAnim>
              <QueueAnim
                key='qa12'
                delay={1200}
                animConfig={{ scale: [1, 0] }}
                forcedReplay
                ease='easeOutBack'
                className='twvImgItem'
              >
                <img key='img12' src={tlv12} alt='' />
                <div key='title12' className='itemTitles'>
                  <div className='mainTitle'>成本报价管理</div>
                  <div>推荐多用途成本及报价方案</div>
                </div>
              </QueueAnim>
            </QueueAnim>
          </OverPack>
        </div>
        {/* 第八屏 数字播动 */}
        <div className='numberScreen'>
          <OverPack playScale={[0.55, 0.8]} replay={false}>
            <QueueAnim
              forcedReplay
              animConfig={{ opacity: [1, 0], translateY: [0, 600] }}
              duration={1000}
              interval={400}
              leaveReverse
              key='numberScreenkey1'
              className='rightArea'
              onEnd={(e) => {
                if (e.type === "enter") {
                  this.setState({
                    num1Animation: {
                      Children: {
                        value: 100,
                        floatLength: 0
                      },
                      duration: 2500
                    },
                    num2Animation: {
                      Children: {
                        value: 41.32,
                        floatLength: 2
                      },
                      duration: 2500
                    },
                    num3Animation: {
                      Children: {
                        value: 100000,
                        floatLength: 0,
                        formatMoney: true
                      },
                      duration: 2500
                    },
                    num4Animation: {
                      Children: {
                        value: 100,
                        floatLength: 0
                      },
                      duration: 2500
                    }
                  });
                }
              }}
            >
              <div key='numberScreenkey11' className='title1'>
                头部鞋履巨头，正在通过智能制造产业协同体系实现价值转型和增长突破
              </div>
              <div key='numberScreenkey12' className='title2'>
                2020年起，行业隐形冠军（典型链主级传统制造企业）与「数乘」团队深度战略合作，通过「数乘」数字化方案和产品体系，进行全链路降本增效，终致力于打造产业级生态服务能力。
              </div>
              <div key='numberScreenkey13' className='numArea'>
                <div className='nums num1'>
                  <div className='top'>
                    <TweenOne
                      className='bigNum'
                      animation={this.state.num1Animation}
                    ></TweenOne>
                    亿+
                  </div>
                  <div className='bottom'>企业营收</div>
                </div>
                <div className='nums num2'>
                  <div className='top'>
                    <TweenOne
                      className='bigNum'
                      animation={this.state.num2Animation}
                    ></TweenOne>
                    %
                  </div>
                  <div className='bottom'>目标环节提效</div>
                </div>
                <div className='nums num1'>
                  <div className='top'>
                    <TweenOne
                      className='bigNum'
                      animation={this.state.num3Animation}
                    ></TweenOne>
                    人
                  </div>
                  <div className='bottom'>组织规模</div>
                </div>
                <div className='nums num2'>
                  <div className='top'>
                    <TweenOne
                      className='bigNum'
                      animation={this.state.num4Animation}
                    ></TweenOne>
                    人+
                  </div>
                  <div className='bottom'>用户深度参与</div>
                </div>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
      </>
    );
  }
}
export default Home;
