import React, { Component } from "react";
import { Drawer, Tabs, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import "./home.css";
import logo from "./imgs/logo.svg";
import logo2 from "./imgs/logo2.svg";
import mapicon from "./imgs/mapicon.svg";
import phone from "./imgs/phone.svg";
import Home from "./Home.jsx";
import About from "./About.jsx";
import Join from "./Join.jsx";
import Footer from "./Footer";
import emailjs from "@emailjs/browser";

class App extends Component {
  formRef = React.createRef();

  state = {
    activeKey: "1",
    headerBlur: "",
    openDrawer: false,
    buttonLoading: false,
    company: "",
    contacter: "",
    contact: "",
    from: "",
    requirement: ""
  };

  //发送邮件
  sendEmail = () => {
    if (this.state.contacter === "" || this.state.contact === "") {
      message.error("请填写联系人及联系方式");
      return;
    }
    this.setState({
      buttonLoading: true
    });
    emailjs
      .sendForm(
        "service_hs16jtq",
        "template_4rdtwe9",
        this.formRef.current,
        "qZTCeTjcleJYsNjVf"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success("信息已提交，我们会尽快联系您", () => {
            this.setState({
              buttonLoading: false
            });
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  handleScroll = (e) => {
    const scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0);
    if (scrollTop > 95) {
      this.setState({
        headerBlur: "blurHeader"
      });
    } else {
      this.setState({
        headerBlur: ""
      });
    }
  };

  handleTabChange = (activeKey) => {
    if (activeKey === "4") {
      //联系我们
      this.callDrawerShow();
      return;
    }

    this.setState({
      activeKey
    });
  };

  handleDrawerClose = () => {
    this.setState({
      openDrawer: false,
      company: "",
      contacter: "",
      contact: "",
      from: "",
      requirement: ""
    });
  };

  callDrawerShow = () => {
    this.setState({
      openDrawer: true
    });
  };

  clickLogo = () => {
    this.setState({
      activeKey: "1"
    });
  };

  renderContent = (activeKey) => {
    switch (activeKey) {
      case "1":
        return (
          <Home
            showDrawer={this.callDrawerShow}
            joinUs={() => this.handleTabChange("3")}
          />
        );
      case "2":
        return (
          <About
            showDrawer={this.callDrawerShow}
            joinUs={() => this.handleTabChange("3")}
          />
        );
      case "3":
        return <Join />;
      default:
        break;
    }
  };

  //点击footer导航
  onNavClick = (activeKey) => {
    this.setState({
      activeKey
    });
  };

  render() {
    return (
      <>
        {/* 页头 */}
        <div className={`pageHeader ${this.state.headerBlur}`}>
          <QueueAnim delay={300} type='left' forcedReplay>
            <img
              key='logo'
              src={logo}
              alt=''
              className='mainHeaderLogo'
              onClick={this.clickLogo}
            />
          </QueueAnim>
          <Tabs
            key='headerTabs'
            className='headerTabs'
            tabBarGutter={56}
            activeKey={this.state.activeKey}
            size='large'
            items={[
              {
                label: `数乘方案`,
                key: "1"
              },
              {
                label: `关于数乘`,
                key: "2"
              },
              {
                label: `加入我们`,
                key: "3"
              },
              {
                label: `联系我们`,
                key: "4"
              }
            ]}
            onChange={this.handleTabChange}
          />
        </div>
        {this.renderContent(this.state.activeKey)}
        <Footer onNavClick={this.onNavClick} />
        <Drawer
          open={this.state.openDrawer}
          mask={false}
          placement='top'
          destroyOnClose={true}
          headerStyle={{ borderBottom: "none", paddingRight: "40px" }}
          closeIcon={false}
          height='100vh'
          zIndex={2001}
          extra={
            <CloseOutlined
              className='drawerClose'
              onClick={this.handleDrawerClose}
            />
          }
          style={{
            background: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(16px)"
          }}
        >
          <div className='drawerContainer'>
            <div className='drwLeft'>
              <img className='drwLeftLogo' src={logo2} alt='' />
              <div className='iconlink'>
                <img src={mapicon} alt='' />
                <a className='elink' href='mailto:we@digitsfly.com'>
                  we@digitsfly.com
                </a>
              </div>
            </div>
            <div className='drwRight'>
              <div className='title'>
                <div>合作咨询</div>
                <div className='right'>
                  <img src={phone} alt='' />
                  <span>021 - 62520600</span>
                </div>
              </div>
              <div className='subTitle'>
                请通过表单提交合作咨询信息，我们会尽快与您取得联系
              </div>
              <form ref={this.formRef}>
                <div className='formItems'>
                  <div className='flexBetween'>
                    <div className='item'>
                      <input
                        type='text'
                        name='company'
                        className={
                          this.state.company.length > 0 ? "inputed" : ""
                        }
                        value={this.state.company}
                        onChange={(e) => {
                          this.setState({
                            company: e.target.value
                          });
                        }}
                      />
                      <label>公司名称</label>
                    </div>
                    <div className='item'>
                      <input
                        type='text'
                        name='contacter'
                        className={
                          this.state.contacter.length > 0 ? "inputed" : ""
                        }
                        value={this.state.contacter}
                        onChange={(e) => {
                          this.setState({
                            contacter: e.target.value
                          });
                        }}
                      />
                      <label>
                        联系人 (先生 / 小姐)&nbsp;
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className='flexBetween'>
                    <div className='item'>
                      <input
                        type='text'
                        name='contact'
                        className={
                          this.state.contact.length > 0 ? "inputed" : ""
                        }
                        value={this.state.contact}
                        onChange={(e) => {
                          this.setState({
                            contact: e.target.value
                          });
                        }}
                      />
                      <label>
                        联系方式 <span style={{ color: "#FF4D4F" }}>*</span>
                      </label>
                    </div>
                    <div className='item'>
                      <input
                        type='text'
                        name='from'
                        className={this.state.from.length > 0 ? "inputed" : ""}
                        value={this.state.from}
                        onChange={(e) => {
                          this.setState({
                            from: e.target.value
                          });
                        }}
                      />
                      <label>从哪里了解到我们</label>
                    </div>
                  </div>
                  <div className='flexBetween'>
                    <div className='item' style={{ width: "100%" }}>
                      <input
                        type='text'
                        name='requirement'
                        className={
                          this.state.requirement.length > 0 ? "inputed" : ""
                        }
                        value={this.state.requirement}
                        onChange={(e) => {
                          this.setState({
                            requirement: e.target.value
                          });
                        }}
                      />
                      <label>你的需求</label>
                    </div>
                  </div>
                </div>
              </form>
              <Button
                type='primary'
                className='leftButtons btnLeft'
                onClick={this.sendEmail}
                loading={this.state.buttonLoading}
              >
                提交
              </Button>
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}
export default App;
