import React, { Component } from "react";
import QueueAnim from "rc-queue-anim";
import { scrollScreen } from "rc-scroll-anim";
import Lottie from "lottie-react";
import join from "./lottiejsons/join.json";
import "./about.css";
import "./about1440.css";
import loca from "./imgs/loca.svg";
import polygon from "./imgs/polygon.svg";
import polygon1 from "./imgs/polygon1.svg";

import { Collapse, Divider } from "antd";
const { Panel } = Collapse;
class Join extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    scrollScreen.unMount();
  }

  render() {
    return (
      <>
        {/* 首屏 */}
        <div className='aboutFirst'>
          <QueueAnim
            duration={2000}
            key='afLeft'
            className='afLeft'
            forcedReplay
          >
            <div key='af1' className='titles'>
              <div className='title2 joinTitle2'>
                数×·<span style={{ color: "#000630" }}>伙伴计划</span>
              </div>
              <div className='title3'>
                在这里你可以遨游在大数据的海洋里。
                <br />
                弹性的工作时间，美味的茶点和小零食，丰富多彩的社团活动……
                <br />
                我们都在期待你的加入！
              </div>
            </div>
          </QueueAnim>
          <Lottie className='afRight' key='afr1' animationData={join} />
        </div>
        {/* 第二屏 */}
        <div className='joinInfo'>
          <div className='title'>招贤纳士 · 等你加入</div>
          <Collapse
            ghost
            expandIconPosition='end'
            expandIcon={({ isActive }) => {
              return isActive ? (
                <img src={polygon1} alt='' />
              ) : (
                <img src={polygon} alt='' />
              );
            }}
          >
            <Panel
              extra={
                <div className='rightloca'>
                  <img src={loca} alt='' />
                  <span>上海 / 广州</span>
                </div>
              }
              header={<div className='panelHeader'>产品岗位</div>}
              key='1'
              className='collapsePanel'
            >
              <div className='panelContainer'>
                <div className='pcTitle'>产品领域</div>
                <div className='content'>
                  ERP (资源)、WMS (仓储)、MES (生产)、SRM (供应链)、APS
                  (排程)、BI (数据)、转型顾问
                </div>
                <div className='pcTitle'>产品段位</div>
                <div className='content'>
                  实习、助理、初中级、高级专家级、总监VP级
                </div>
                <Divider
                  dashed
                  style={{ borderColor: "#B0B2C1", margin: "48px 0" }}
                />
                <div className='pcTitle'>岗位要求</div>
                <div className='content'>
                  <p>1、本科及以上学历</p>
                  <p>
                    2、对传统制造业数字化转型及产业互联网领域的软件产品 (SaaS)
                    创新具有极大的热情
                  </p>
                  <p>
                    3、想得清楚，做的干净，讲得明白，有较强的自学习能力和自修正能力
                  </p>
                  <p>4、三观很正，踏实稳健，爱思考有想法但不喜“空谈阔论”</p>
                  <p>
                    5、基于业务价值流，对用户需求进行梳理和分析，形成清晰的产品设计输出
                    (初中级职位)
                  </p>
                  <p>
                    6、基于业务运营模式和用户理解，对场景进行解构、分析和建模，构建产品构架和设计输出
                    (高级职位)
                  </p>
                  <p>
                    7、有To B 方向相关产品经验或从业经验
                    (ERP、WMS、SRM、MES、APS 等) 的优先
                  </p>
                  <p>
                    8、前期需要要以周为单位的出差，在用户现场进行深度调研和场景体验实践
                  </p>
                </div>
                <div className='emaillinkdiv'>
                  简历投递至：
                  <a
                    className='emaillink'
                    href='mailto:alice.gao@digitsfly.com'
                  >
                    alice.gao@digitsfly.com
                  </a>
                </div>
              </div>
            </Panel>
            <Panel
              extra={
                <div className='rightloca'>
                  <img src={loca} alt='' />
                  <span>上海</span>
                </div>
              }
              header={<div className='panelHeader'>设计岗位</div>}
              key='2'
              className='collapsePanel'
            >
              <div className='panelContainer'>
                <div className='pcTitle'>设计领域</div>
                <div className='content'>
                  产品设计、用户研究、品牌视觉设计、动效设计
                </div>
                <div className='pcTitle'>设计段位</div>
                <div className='content'>
                  助理 (实习)、初级、中级、高级、资深、专家
                </div>
                <Divider
                  dashed
                  style={{ borderColor: "#B0B2C1", margin: "48px 0" }}
                />
                <div className='pcTitle'>产品设计岗位要求</div>
                <div className='content'>
                  <p>
                    1、精通 Figma，Photoshop，illustrator，AE，Pr，C4D
                    等常用设计软件
                  </p>
                  <p>2、有 B 端行业的体验设计经验</p>
                  <p>
                    3、具备问题导向、拥有强大的自驱力和主人翁意识、能够有逻辑、有依据地提出解决方案
                  </p>
                  <p>
                    4、具备成熟的方法和经验，熟练运用交互和原型工具，有提出假设并快速验证假设的能力
                  </p>
                  <p>
                    5、具备良好的沟通能力和表达能力，实践以人为中心的交互设计
                    (HCD)，积极与设计过程中的每个角色 (客户 / 用户 / 协作部门 /
                    设计师团队) 沟通，取得反馈以推动设计进程
                  </p>
                  <p>
                    6、深刻理解企业级 B2B 设计方法，具备强大的 B2B
                    交互设计功底以理清复杂的交互状态完成需求，同时能够针对客户特性打造亲和客户和用户的
                    UI 界面，兼具美感与易学易懂
                  </p>
                  <p>
                    7、重视方法论建设，并习惯于以业务结果为导向的思考方式，向设计团队和关键决策群体分享和布道你的用户体验理念和成果
                  </p>
                </div>
                <div className='pcTitle'>这些会让我们眼前一亮</div>
                <div className='content'>
                  <p>
                    1、具有化繁为简的能力，善用图形语义来精准表达想法的“魔法师”
                  </p>
                  <p>2、对国内外设计趋势及 B 端产品有深刻的理解与广阔的视野</p>
                  <p>3、毕业于计算机、设计类院校的 HCI 相关专业</p>
                  <p>4、有开发（尤其前端）经验</p>
                </div>
                <Divider
                  dashed
                  style={{ borderColor: "#B0B2C1", margin: "48px 0" }}
                />
                <div className='pcTitle'>用户研究岗位要求</div>
                <div className='content'>
                  <p>
                    1、本科及以上学历，心理学、社会学、市场营销、统计、设计艺术、人机交互等相关专业，3
                    年以上用户研究或市场调研经验
                  </p>
                  <p>
                    2、熟悉各种研究方法，有极强的定性访谈及定量数据分析能力，熟悉聚类、回归等分析方法，对用户研究/用户体验评估有深入的了解
                  </p>
                  <p>
                    3、较强的问题分析能力，有自己的产品见解和产品思维体系，能独立分析的产品现状及问题
                  </p>
                  <p>
                    4、极强的报告撰写能力，同时能熟练使用 SPSS 或 SAS 等统计软件
                  </p>
                  <p>
                    5、具备良好的沟通能力和推动力，富有团队精神，具有良好自我管理能力、执行力和沟通技巧
                  </p>
                  <p>6、有数据挖掘背景或商业分析经验者优先</p>
                  <p>
                    7、良好的英文沟通能力，能阅读相关专业文章、撰写英文报告者优先
                  </p>
                </div>
                <Divider
                  dashed
                  style={{ borderColor: "#B0B2C1", margin: "48px 0" }}
                />
                <div className='pcTitle'>品牌视觉设计岗位要求</div>
                <div className='content'>
                  <p>
                    1、大学专科及以上学历，美术、平面设计、广告等相关专业
                    (简历请附，上个人作品或链接)
                  </p>
                  <p>
                    2、3
                    年以上相关工作经验，精通各类设计软件，有出色的手绘功底，优秀的动效和视频剪辑能力；若还具有熟练的
                    3D 建模能力可加分
                  </p>
                  <p>
                    3、具备优秀的视觉审美能力、创意策略和概念的理解能力，扎实的美术基础，色彩控制能力强，有丰富的各种形式的品牌类设计经验
                  </p>
                  <p>
                    4、富有分享精神和沟通技巧，较强责任心和团队协调能力，能承受较强的工作压力，工作责任心强，严谨负责
                  </p>
                </div>
                <Divider
                  dashed
                  style={{ borderColor: "#B0B2C1", margin: "48px 0" }}
                />
                <div className='pcTitle'>动效设计岗位要求</div>
                <div className='content'>
                  <p>1、负责产品的动效设计，协助开发跟进实现动态效果</p>
                  <p>
                    2、负责创意动效设计，完成产品功能演示类 Demo
                    的设计制作及动效相关规范设计
                  </p>
                  <p>
                    3、参与到用研、交互、视觉、各个设计环节，共同探索，持续优化产品方案
                  </p>
                  <p>
                    4、2
                    年以上动效设计相关工作经验，对移动设备、多平台领域设计有深度研究
                  </p>
                  <p>
                    5、精通
                    AE、PS、交互原型、三维、动效、渲染、剪辑等软件工具，有相关编程能力更佳
                  </p>
                  <p>
                    6、有较强的学习能力，逻辑分析能力，主观能动性强，有持续改进的自驱意识
                  </p>
                  <p>7、工作积极主动，有责任感，具备优秀的沟通和团队协作能力</p>
                </div>
                <div style={{ display: "flex" }}>
                  <div className='pcTitle' style={{ marginRight: "32px" }}>
                    投递简历时请标注应聘岗位
                  </div>
                  <div className='emaillinkdiv'>
                    简历投递至：
                    <a
                      className='emaillink'
                      href='mailto:jessica.fang@digitsfly.com'
                    >
                      jessica.fang@digitsfly.com
                    </a>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel
              extra={
                <div className='rightloca'>
                  <img src={loca} alt='' />
                  <span>上海 / 广州 / 西安</span>
                </div>
              }
              className='collapsePanel'
              header={<div className='panelHeader'>开发岗位</div>}
              key='3'
            ></Panel>
          </Collapse>
        </div>
      </>
    );
  }
}

export default Join;
