import React, { Component } from "react";
import QueueAnim from "rc-queue-anim";
import { OverPack, scrollScreen } from "rc-scroll-anim";
import { Button, Tabs } from "antd";
import Lottie from "lottie-react";
import about1 from "./lottiejsons/about1.json";
import number1 from "./lottiejsons/number1.json";
import number2 from "./lottiejsons/number2.json";
import number3 from "./lottiejsons/number3.json";
import map from "./lottiejsons/map.json";
import coffee from "./lottiejsons/coffee.json";
import aircraft from "./lottiejsons/aircraft.json";
import "./about.css";
import "./about1440.css";
import "./about1024.css";
import mark from "./imgs/mark.svg";
import at1 from "./imgs/at1.png";
import at2 from "./imgs/at2.png";
import at3 from "./imgs/at3.png";
import ftimg1 from "./imgs/ftimg1.svg";
import ftimg2 from "./imgs/ftimg2.svg";
import ftimg3 from "./imgs/ftimg3.svg";
import ftimg4 from "./imgs/ftimg4.svg";
import phone from "./imgs/phone.svg";
import sh from "./imgs/sh.png";
import gz from "./imgs/gz.png";

export default class About extends Component {
  state = {
    thinkkey: "11",
    gzOrSh: "1"
  };

  itvl = null;

  componentDidMount() {
    scrollScreen.unMount();
    window.scrollTo(0, 0);

    // 5s轮播
    this.itvl = setInterval(() => {
      let temp = Number(this.state.thinkkey) + 1;
      if (temp === 14) {
        temp = 11;
      }
      this.onThreeTabsChange(String(temp));
    }, 5000);
  }

  componentWillUnmount() {
    if (this.itvl) clearInterval(this.itvl);
  }

  clickConnect = () => {
    this.props.showDrawer();
  };

  clickJoin = () => {
    this.props.joinUs();
  };

  onThreeTabsChange = (key) => {
    this.setState({
      thinkkey: key ?? "11"
    });
  };

  render() {
    return (
      <>
        {/* 首屏 */}
        <div className='aboutFirst'>
          <QueueAnim interval={0} key='afLeft' className='afLeft' forcedReplay>
            <div key='af1' className='titles'>
              <div className='title1'>数乘·传统制造业</div>
              <div className='title2'>数字化转型专家</div>
              <div className='title3'>
                持续鞋服先进“组织”的探索与思考，打造鞋服智能制造协同体系
              </div>
            </div>
            <div key='af2' className='s1leftButtons'>
              <Button
                type='primary'
                className='leftButtons btnLeft'
                onClick={this.clickConnect}
              >
                联系我们
              </Button>
              <Button
                type='default'
                style={{
                  borderWidth: "2px"
                }}
                className='leftButtons btnRight'
                onClick={this.clickJoin}
              >
                加入我们
              </Button>
            </div>
          </QueueAnim>
          <Lottie className='afRight' key='afr1' animationData={about1} />
        </div>
        {/* 第二屏 */}
        <OverPack replay playScale={[0.5, 0.6]} className='aboutSecond'>
          <QueueAnim
            key='aboutSecond'
            duration={500}
            leaveReverse
            animConfig={{
              opacity: [1, 0],
              translateY: [0, 300]
            }}
            forcedReplay
            className='aboutSecondDiv'
          >
            <img key='mark1' src={mark} alt='' />
            <div key='astext' className='text'>
              <div className='title1'>公司简介</div>
              <div className='title2'>
                数乘科技（DigitsFly）是一家以数字化技术赋能传统制造业升级的下一代科技公司。我们致力于通过技术创新和产业深耕，提升鞋服研发设计、生产制造、供应链等全链路高效无间协作和柔性输出能力，打造鞋服产业智能制造协同体系。
              </div>
              <div>
                数乘总部位于上海，在广州设有分部，拥有近100人的产研和服务团队。我们依托黄金赛道，深入垂直产业，潜心研究业务，踏实构建形成面对复杂业务场景和贴身化需求的结构化顾问服务能力，框架化产品能力和底层技术能力。
              </div>
            </div>
          </QueueAnim>
        </OverPack>
        {/* 第三屏 */}
        <OverPack playScale={[0.45, 0.5]} replay className='aboutThird'>
          <QueueAnim
            forcedReplay
            key='aboutThird'
            duration={600}
            leaveReverse
            animConfig={{
              opacity: [1, 0],
              translateY: [0, 400]
            }}
            className='title'
          >
            <div key='atTitle'>使命与践行</div>
          </QueueAnim>
          <QueueAnim
            forcedReplay
            key='about3imgs'
            duration={800}
            interval={300}
            // delay={100}
            animConfig={{
              scale: [1, 0],
              opacity: [1, 0]
            }}
            className='imgs'
          >
            <div key='at1' className='imgItem'>
              <img src={at1} alt='' />
              <div>服务传统鞋服制造业实现高价值升级转型</div>
            </div>
            <div key='at2' className='imgItem'>
              <img src={at2} alt='' />
              <div>致力成为行业级精实技术服务引领品牌</div>
            </div>
            <div key='at3' className='imgItem'>
              <img src={at3} alt='' />
              <div>乘势而上，行稳致远，厚积薄发，突破边界</div>
            </div>
          </QueueAnim>
        </OverPack>
        {/* 第四屏 */}
        <OverPack playScale={[0.2, 0.2]} replay className='aboutFourth'>
          <QueueAnim
            forcedReplay
            key='fourthtitle1'
            duration={800}
            interval={250}
            leaveReverse
            animConfig={{
              opacity: [1, 0],
              translateY: [0, 200]
            }}
            className='titles'
          >
            <div key='title1' className='title1'>
              我们的标签
            </div>
            <div key='title2' className='title2'>
              我们从产业中来,我们曾经是「脑热技术Geek」、「一线运营管理」、「光鲜咨询顾问」、「大厂产品专家」、「优雅设计师」、「不迷毕业生」...
              我们到产业中去，我们热爱制造业，我们喜欢解决问题，我们享受思考，我们踏实做事，我们充满期待...
            </div>
          </QueueAnim>
          <QueueAnim
            forcedReplay
            key='ftimg4'
            duration={800}
            interval={300}
            // delay={200}
            // leaveReverse
            animConfig={{
              scale: [1, 0],
              opacity: [1, 0]
            }}
            className='imgs'
          >
            <div key='ftimg1' className='imgItem'>
              <img src={ftimg1} alt='' />
              <div>理想</div>
            </div>
            <div key='ftimg2' className='imgItem'>
              <img src={ftimg2} alt='' />
              <div>专业</div>
            </div>
            <div key='ftimg3' className='imgItem'>
              <img src={ftimg3} alt='' />
              <div>开放</div>
            </div>
            <div key='ftimg4' className='imgItem'>
              <img src={ftimg4} alt='' />
              <div>担当</div>
            </div>
          </QueueAnim>
        </OverPack>
        {/* 第五屏 */}
        <OverPack playScale={[0.5, 0.5]} replay className='aboutFifth'>
          <QueueAnim
            forcedReplay
            key='fifthque'
            duration={800}
            interval={300}
            // leaveReverse
            animConfig={[
              {
                opacity: [1, 0],
                translateY: [0, 500]
              },
              {
                opacity: [1, 0],
                translateY: [0, 100]
              }
            ]}
            className='fifthtitle'
          >
            <div key='ftitle1' className='title1'>
              关于未来的思考
            </div>
            <div key='fifthtabs'>
              <Tabs
                centered
                className='fifthtabs'
                tabBarGutter={180}
                activeKey={this.state.thinkkey}
                items={[
                  {
                    label: "风口转变",
                    key: "11"
                  },
                  {
                    label: "未来十年",
                    key: "12"
                  },
                  {
                    label: "现实当下",
                    key: "13"
                  }
                ]}
                onChange={this.onThreeTabsChange}
              />
            </div>
            {this.state.thinkkey === "11" && (
              <div key='tabcontent' className='tabcontent'>
                <Lottie
                  className='numlot'
                  animationData={number1}
                  loop={false}
                />
                <QueueAnim
                  animConfig={[
                    { opacity: [1, 0], translateX: [0, 1000] },
                    { opacity: [1, 0] }
                  ]}
                  duration={1000}
                  forcedReplay
                >
                  <div key='thinkright1' className='content'>
                    如今，以「平台和流量」为思维导向的「消费互联网」增长时代已经开始与我们挥手作别，另一个以「集群和效率」为价值核心的「
                    产业互联网 or 互联网+」全新时代正在崛起！
                    <br />
                    <br />
                    风口的转向，带来的是一波裁员后从To C向To B
                    从业和创业热度的盲目迁移？还是新一轮为投资人量身打造的 To VC
                    商业模式的诞生？
                    <br />
                    <br />
                    风口的背后，都说是市场经济客观发展规律下，从「需求侧」到「供给侧」的进阶升级，看到的是国家层面发力振兴实体经济，重塑「中国制造品牌」从高速增长转向高质量发展阶段的顶层战略布局！
                  </div>
                </QueueAnim>
              </div>
            )}
            {this.state.thinkkey === "12" && (
              <div key='tabcontent' className='tabcontent'>
                <Lottie
                  className='numlot'
                  animationData={number2}
                  loop={false}
                />
                <QueueAnim
                  animConfig={[
                    { opacity: [1, 0], translateX: [0, 1000] },
                    { opacity: [1, 0] }
                  ]}
                  duration={1000}
                  forcedReplay
                >
                  <div key='thinkright2' className='content'>
                    过去一年，在中国，围绕着「质变」的「十四五规划」中，除了「人口」和「绿色」等话题之外，支撑中国经济「稳中有进」的关键词无疑是「数字中国」和「产业升级」！
                    <br />
                    <br />
                    未来十年，中国，在「内需外卡」的新形势下，摆脱人口红利消失的阴影，构建以「制造业数字化转型」为驱动的「中国产业新供应链」迫在眉睫，同时，推进数字化转型的「万亿级工业软件赛道」将迎来绝佳机会！
                    <br />
                    <br />
                    我们当前面向的运动鞋履产业赛道，预计2025年全球市场规模近4000亿美元，复合增长率14%+，头部制造ROE20%+。疫情终会散去，大健康趋势及时尚潮流元素夹持，将加速驱动运动鞋服市场高速增长并持续催生新晋潮流品牌。面对如此之大体量且活跃的传统制造业，数字化技术赋能升级将大有可为。
                  </div>
                </QueueAnim>
              </div>
            )}
            {this.state.thinkkey === "13" && (
              <div key='tabcontent' className='tabcontent'>
                <Lottie
                  className='numlot'
                  animationData={number3}
                  loop={false}
                />
                <QueueAnim
                  animConfig={[
                    { opacity: [1, 0], translateX: [0, 1000] },
                    { opacity: [1, 0] }
                  ]}
                  forcedReplay
                  duration={1000}
                >
                  <div key='thinkright3' className='content'>
                    然而现在，当我们，亲身踏入「中国制造一线」，眼前看到的却是勉强算是工业2.0水平的现状，愿景规划和现实情景的尖锐对比，显得颇为冲突和违和！
                    <br />
                    <br />
                    但是此刻，当我们，深入走进「产业业务腹地」，为之震撼的确是支撑中国制造供应链全球竞争力的产业KNOW-HOW和管理实践的储备，这足以让产业转型升级蓄势待发！
                    未来五年，我们，唯有依托黄金赛道，「潜心」深耕垂直产业，「踏实」构建业务壁垒，「精心」沉淀数字化产品能力和技术服务能力，方能拥抱大势，赢得时代!
                  </div>
                </QueueAnim>
              </div>
            )}
          </QueueAnim>
        </OverPack>
        {/* 第六屏 */}
        <OverPack playScale={[0.7, 0.7]} replay className='aboutSixth'>
          <div key='aboutSixth' className='topArea'>
            <div
              className='sixthleft'
              onClick={() => {
                if (this.state.gzOrSh === "1") {
                  //上海
                  window.open("https://www.amap.com/place/B0FFG4L0PS");
                } else {
                  //广州
                  window.open("https://www.amap.com/place/B00141UC0C");
                }
              }}
            >
              <img
                className='mapimg'
                src={this.state.gzOrSh === "1" ? sh : gz}
                alt=''
              />
              <Lottie className='leftlt' loop={false} animationData={map} />
            </div>
            <QueueAnim
              className='sixthright'
              forcedReplay
              duration={600}
              leaveReverse
              animConfig={{
                opacity: [1, 0],
                translateY: [0, 500]
              }}
            >
              <div key='srt1' className='srTitle'>
                公司地址
              </div>
              <div className='twoAddress'>
                {this.state.gzOrSh === "1" ? (
                  <div key='srt2' className='infoArea'>
                    <div key='infokey' className='info'>
                      <div className='infotitle'>
                        <img src={phone} alt='' />
                        <span>上海 (总部)</span>
                      </div>
                      <div className='contectInfo'>
                        <p>地址：上海市浦东新区张江高科博霞路22号S座3楼</p>
                        <p>电话：021 - 62520600</p>
                        <p>
                          邮箱：
                          <a className='elink' href='mailto:we@digitsfly.com'>
                            we@digitsfly.com
                          </a>
                        </p>
                      </div>
                      <Lottie className='coffee' animationData={coffee} />
                    </div>
                    <div
                      key='otherblock1'
                      className='otherblock'
                      onClick={() => {
                        this.setState({
                          gzOrSh: "2"
                        });
                      }}
                    >
                      <div
                        className='infotitle'
                        style={{ marginLeft: "32px", color: "#666E82" }}
                      >
                        <img src={phone} alt='' />
                        <span>广州 / 西安</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key='srt3' className='infoArea'>
                    <div
                      key='otherblock2'
                      className='otherblock'
                      onClick={() => {
                        this.setState({
                          gzOrSh: "1"
                        });
                      }}
                    >
                      <div
                        className='infotitle'
                        style={{ marginLeft: "40px", color: "#666E82" }}
                      >
                        <img src={phone} alt='' />
                        <span>上海 (总部)</span>
                      </div>
                    </div>
                    <div
                      key='infokey'
                      className='info'
                      style={{ paddingLeft: "40px" }}
                    >
                      <div className='infotitle'>
                        <img src={phone} alt='' />
                        <span>广州 / 西安</span>
                      </div>
                      <div className='contectInfo'>
                        <p>广州：广州市花都区迎宾大道179号京仕广场A座3楼</p>
                        <p>西安：西安市高新区天谷八路211号环普科技园G3座12楼</p>
                        <p>
                          邮箱：
                          <a className='elink' href='mailto:we@digitsfly.com'>
                            we@digitsfly.com
                          </a>
                        </p>
                      </div>
                      <Lottie className='coffee' animationData={coffee} />
                    </div>
                  </div>
                )}
              </div>
            </QueueAnim>
          </div>
          <QueueAnim
            forcedReplay
            duration={800}
            delay={600}
            leaveReverse
            animConfig={{
              opacity: [1, 0],
              scale: [1, 0]
            }}
          >
            <div
              key='joinButton'
              className='joinButton'
              onClick={this.clickJoin}
            >
              加入我们
            </div>
          </QueueAnim>
          <QueueAnim
            forcedReplay
            duration={800}
            delay={600}
            leaveReverse
            animConfig={{
              opacity: [1, 0]
            }}
          >
            <Lottie
              key='aircraft'
              animationData={aircraft}
              className='aircraft'
              loop={false}
            />
          </QueueAnim>
        </OverPack>
      </>
    );
  }
}
