import React, { Component } from "react";
import "./home.css";

class Footer extends Component {
  render() {
    return (
      <div className='pageFooter'>
        <div className='footerTop'>
          <dl className='left'>
            <dt>快速导航</dt>
            <dd
              onClick={() => {
                this.props.onNavClick("1");
              }}
            >
              数乘方案
            </dd>
            <dd
              onClick={() => {
                this.props.onNavClick("2");
              }}
            >
              关于我们
            </dd>
            <dd
              onClick={() => {
                this.props.onNavClick("3");
              }}
            >
              加入我们
            </dd>
          </dl>
          <dl>
            <dt>联系我们</dt>
            <dd>上海市浦东新区张江高科博霞路22号S座3楼</dd>
            <dd>广州市花都区迎宾大道179号京仕广场A座3楼</dd>
            <dd>西安市高新区天谷八路211号环普科技园G3座12楼</dd>
            <dd>
              邮箱
              <a className='emaillink' href='mailto:we@digitsfly.com'>
                we@digitsfly.com
              </a>
            </dd>
          </dl>
        </div>
        <div className='copyrightInfo'>
          © 2022 数乘 digitsfly ｜ 数乘 (上海) 科技有限公司版权所有 ｜&nbsp;
          <a
            href='https://beian.miit.gov.cn/'
            target='_blank'
            rel='noopener noreferrer'
          >
            沪 ICP 备 2022034614 号
          </a>
        </div>
      </div>
    );
  }
}
export default Footer;
